@use "../../../assets/sass/variables" as *;
@use "../../../assets/sass/mixins" as *;

.change-language {
	text-align: center;
	color: white;
}

.globe-wrapper {
	padding: 13px 15px !important;
	border-radius: 16rem;
	background-color: #dfc9b1;
	@include desktop {
		padding: 11px 13px !important;
	}
	.globe-image {
		width: 20px;
	}
}

.language-change {
	color: white;
	.language-font-label {
		font-size: 12px;
		color: #fff;
		font-weight: 300;
	}
	.form-group {
		margin-bottom: 0;
		color: $white;
	}
	.react-select-container {
		z-index: 2;

		.react-select {
			&__control {
				background-color: transparent;
				border: 0;
				width: 80px;
				min-height: 24px;
				border: none;
				.react-select__single-value {
					color: #fff;
				}
			}

			&__value-container {
				padding: 0px;

				@include rtl {
					overflow: visible;
				}
			}
			&__indicator {
				padding: 0px;
			}

			&__single-value {
				justify-content: end;
				@include tablet {
					justify-content: start;
				}
				.select-box-lbl {
					display: none;
					color: $white;
					@include tablet {
						display: block;
					}
				}
			}
			&__option {
				color: #000;
				&:hover {
					background-color: lighten(#e6d3c4, 5%);
				}

				&--is-selected {
					background-color: var(--background-color-front);
					color: rgb(0, 0, 0);

					&:hover {
						background-color: var(--background-color-front);
						color: rgb(0, 0, 0);
					}
				}
			}
		}
	}
}
