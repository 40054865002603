@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;

.footer-wrapper {
	background-color: #5e5249;
	padding-top: 80px;
	padding-bottom: 50px;
	@include tablet {
		padding-top: 50px;
		padding-bottom: 16px;
	}
	@include medium-small-width {
		padding-top: 30px;
		padding-bottom: 16px;
	}
}

.footer-heading {
	color: $white;
	font-size: 17px;
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 30px;
	@include mobile {
		margin-bottom: 5px;
	}
}
.react-select__input {
	color: transparent;
}
.footer-link-wrapper {
	@include rtl {
		text-align: right;
	}
	.footer-link-item {
		margin-bottom: 18px;
		font-size: 16px;
		font-weight: 300;
		@include mobile {
			margin-bottom: 8px;
			font-size: 13px;
		}

		.footer-link {
			color: $white;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
.social-link-main-wraper {
	background-color: transparent;
	.company-logo-wraper {
		width: 230px;
	}
	.footer-company-description {
		color: #eaeaea;
		padding: 30px 50px 30px 0px;
		font-weight: 300;
		line-height: 1.7;
	}
}
.social-link-wrapper {
	display: flex;
	margin-bottom: 30px;
	padding: 0;

	.social-link {
		padding: 12px;
		flex: 0 0 40px;
		max-width: 60px;
		text-align: center;
		color: #9e9e9e;
		background-color: var(--background-secondary-color);
		border-radius: 50%;
		line-height: 1;
		transition: all 300ms ease-in-out;
		margin-left: 0px;
		margin-right: 10px;
		@include md-mobile {
			padding: 8px;
		}
		@include rtl {
			margin-right: 0;
			margin-left: 10px;
		}

		&[title="Facebook"]:hover {
			border-color: $brand-facebook;
			color: $brand-facebook;
		}

		&[title="Facebook-Messenger"]:hover {
			border-color: $brand-facebook;
			color: $brand-facebook;
		}

		&[title="Twitter"]:hover {
			border-color: $brand-twitter;
			color: $brand-twitter;
		}

		&[title="Telegram"]:hover {
			border-color: $brand-twitter;
			color: $brand-twitter;
		}

		&[title="Linkedin"]:hover {
			border-color: $brand-linkedin;
			color: $brand-linkedin;
		}

		&[title="Youtube"]:hover {
			border-color: $brand-youtube;
			color: $brand-youtube;
		}

		.social-icon {
			font-size: 28px;
			color: #5e5249;
		}
	}
}
// .change-language {
// 	width: 190px;

// 	.react-select {
// 		&__control {
// 			background-color: transparent;
// 		}
// 		&__option {
// 			&:hover {
// 				background-color: lighten(#ddd, 5%);
// 			}

// 			&--is-selected {
// 				background-color: var(--background-color-front);
// 				color: white;

// 				&:hover {
// 					background-color: var(--background-color-front);
// 					color: white;
// 				}
// 			}
// 		}
// 	}
// }

.footer-disclaimer,
.footer-copyright {
	font-size: 90%;
	padding-top: 15px;
	color: #eaeaea;
	text-align: center;
	font-weight: 300;
}

.custom-footer-link {
	color: $white;
	text-decoration: none;
	font-size: 16px;
	font-weight: 300;
	@include mobile {
		font-size: 13px;
	}
}
.custom-footer-link:hover {
	color: #31333d;
	text-decoration: underline;
}

.footer-main-wrap {
	@include mobile {
		margin-bottom: 15px;
	}
}
