$font-size-base: 16px;
$font-color-base: #31333d;
$font-color-dark: #1b1b1b;
$font-color-light: #666666;
$font-family-base: "montserrat-arabic";

$white: #fff;
$gray: #dfdfdf;
$black: #000;
$red: #f1574b;
$green: #51d73f;
$dark-green: #3fba7a;
$btn-secondary: #6c757d;

$brand-facebook: #4267b2;
$brand-twitter: #1da1f2;
$brand-youtube: #ff0000;
$brand-linkedin: #2867b2;

$base-color: #6048fe;
$base-lightest-color: #f0eeff;
$footer-bg: #f8f8f9;
$navbar-link-color: #1b1b1b;
$footer-link-color: #31333d;
$two-factor-img-bg-color: #f6f5ff;

$text-important: red;

$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;
$font-black: 800;

$border-radius: 4px;
$input-border-color: #ced4da;
$input-border: 1px;
$input-height: 50px;
$btn-border-radius: 50px;
$box-border-radius: 30px;
$box-medium-radius: 20px;

$box-border-color: #ececec;
$light-border-color: #ebebeb;

$section-padding: 65px;
$section-tablet-padding: 35px;
$section-mobile-padding: 40px;
$input-btn-padding-x: 1rem !default;
