.section-brdr-top {
	border-top: 1px solid #cbcbcb;
}
.mb-30 {
	margin-bottom: 30px;
}
.primary-color {
	color: var(--background-color-front);
}
.secondary-color {
	color: var(--background-secondary-color);
}

.py-75 {
	padding-top: 75px;
	padding-bottom: 75px;
}

.section-title {
	font-size: 30px;
	font-weight: $font-regular;
	@include medium-small-width {
		text-align: center;
	}

	&-wrapper {
		margin-bottom: 25px;
	}

	&-brif {
		font-size: 15px;
		color: $font-color-light;
		margin-bottom: 0;
		margin-top: 5px;
	}
}

.fs-15 {
	font-size: 15px;
}

.fs-14 {
	font-size: 14px;
}

.fs-13 {
	font-size: 13px;
}

.pagination {
	.page-item {
		margin-left: 5px;

		.page-link {
			border: 0;
			font-weight: $font-bold;
			color: $font-color-base;
			border-radius: 50%;
			width: 35px;
			height: 35px;
			padding: 8px;
			text-align: center;
			font-size: 14px;

			&:hover {
				background-color: lighten(#ddd, 7%);
			}
		}

		&.active {
			.page-link {
				background-color: var(--background-color-front);
				color: $white;
			}
		}
	}
}

.custom-input-group {
	position: relative;

	&-text {
		font-weight: $font-bold;
		color: $font-color-light;
		position: absolute;
		// height: 100%;
		top: 13px;
		display: flex;
		align-items: center;
		min-width: 35px;
		padding: 0 10px;
		justify-content: center;
	}

	&-prepend {
		.custom-input-group-text {
			left: 0;
			@include rtl {
				right: 0;
				left: auto;
			}
		}

		.form-control {
			padding-left: 52px;
			@include rtl {
				padding-right: 52px !important;
			}
		}
	}

	&-append {
		.custom-input-group-text {
			right: 0;
			@include rtl {
				left: 0;
				right: auto;
			}
		}
		.form-control {
			padding-right: 35px;
			@include rtl {
				padding-left: 35px;
			}
		}
	}
}

.form-separator {
	margin-top: 50px;
	margin-bottom: 50px;
}

.action-btn-group {
	.btn {
		&.btn-sm {
			padding: 7px 10px;
			width: 35px;
			height: 35px;

			&:not(:hover) {
				background-color: #f3f6f9;
				border-color: #f3f6f9;
			}
		}
	}
}

.tooltip {
	.tooltip-inner {
		max-width: 200px;
		padding: 10px 18px;
		color: $font-color-base;
		background-color: $white;
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
	}
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow:before,
.bs-tooltip-bottom .arrow:before {
	border-bottom-color: $white;
}

.ec-custom-file {
	position: relative;

	&-input {
		position: absolute;
		width: 1px;
		height: 1px;
		opacity: 0;
	}
}

.question-mark {
	cursor: pointer;
	margin-left: 7px;
	font-size: 14px;
	@include rtl {
		margin-right: 7px;
	}
}

.custom-list {
	padding-left: 0;
	padding-right: 0;
	& li {
		list-style: none;
	}

	& .custom-checkbox {
		display: flex;
		align-items: center;
		cursor: pointer;
		font-size: 15px; /* Customize text size if needed */
	}

	/* Hide the default checkbox */
	& .custom-checkbox input[type="checkbox"] {
		display: none;
	}

	/* Create a custom checkbox */
	& .custom-checkbox .checkmark {
		width: 20px; /* Set width for larger size */
		height: 20px; /* Set height for larger size */
		// background-color: rgb(84, 84, 84);
		border: 1px solid var(--background-color-front);
		border-radius: 4px; /* Rounded corners if needed */
		position: relative;
		display: inline-block;
		margin-right: 10px; /* Space between checkbox and text */
		transition: background-color 0.3s;
	}
	& .custom-checkbox input[type="checkbox"]:checked + .checkmark {
		background-color: var(--background-color-front);
	}

	/* Show the checkmark when the checkbox is checked */
	& .custom-checkbox input[type="checkbox"]:checked + .checkmark::after {
		content: "";
		position: absolute;
		left: 6px;
		top: 1px;
		width: 8px;
		height: 15px;
		border: solid white; /* Checkmark color */
		border-width: 0 4px 4px 0;
		transform: rotate(45deg);
	}

	/* Hover effect to change background or add a border */
	& .custom-checkbox:hover .checkmark {
		background-color: darkbrown; /* Slightly darker on hover */
	}
}

.custom-btn-group {
	&.custom-btn-group-lg {
		.custom-btn-group-item {
			.custom-btn-group-label {
				padding: 12px 25px;
			}
		}
	}

	.custom-btn-group-item {
		&:first-child {
			.custom-btn-group-label {
				border-top-left-radius: $border-radius;
				border-bottom-left-radius: $border-radius;

				@include rtl {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;

					border-top-right-radius: $border-radius;
					border-bottom-right-radius: $border-radius;
				}
			}
		}

		&:last-child {
			.custom-btn-group-label {
				border-top-right-radius: $border-radius;
				border-bottom-right-radius: $border-radius;

				@include rtl {
					border-top-left-radius: $border-radius;
					border-bottom-left-radius: $border-radius;

					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
		}
	}

	.custom-btn-group-input {
		&:checked {
			& ~ .custom-btn-group-label {
				background-color: var(--background-color-front);
				border-color: var(--background-color-front);
				color: #fff;

				&.custom-btn-group-danger {
					background-color: $red;
					border-color: $red;
					color: #fff;
				}

				&.custom-btn-group-secondary {
					background-color: $btn-secondary;
					border-color: $btn-secondary;
					color: #fff;
				}
			}
		}
	}
}

.custom-btn-group {
	display: flex;

	&-item {
		position: relative;
		margin-left: -1px;
	}

	&-input {
		position: absolute;
		opacity: 0;
		overflow: hidden;
	}

	&-label {
		transition: all 200ms ease-in-out;
		border: 1px solid $gray;
		text-align: center;
		padding: 6px 18px;
		cursor: pointer;
		margin-bottom: 0;
	}
}

.top-banner-wrapper {
	padding-top: 110px;
	padding-bottom: 110px;
	position: relative;

	@include tablet {
		padding-top: 70px;
		padding-bottom: 70px;
	}

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.7);
	}

	.top-banner-bgImg {
		background-size: cover;
		filter: blur(8px);
		-webkit-filter: blur(8px);
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
	}
}

.campaign-title {
	@include mobile {
		text-align: center;
		margin-top: 30px;
	}

	&-wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin-bottom: 60px;

		@include mobile {
			justify-content: center;
		}
	}

	&-img {
		@include box-shadow(0, 2px, 5px, rgba(67, 54, 102, 0.15));
		background-color: $white;
		@include rounded(4px);
		overflow: hidden;
		flex: 0 0 98px;
		max-width: 98px;
		margin-right: 22px;

		@include mobile {
			margin-right: 0;
		}

		img {
			width: 98px;
			height: 98px;
			object-fit: cover;
		}
	}

	&-name {
		color: $white;
		font-size: 35px;
		font-weight: $font-bold;
	}

	&-brif {
		color: $white;
	}

	&-category {
		display: flex;
		flex-wrap: wrap;
		margin-top: 10px;

		@include mobile {
			justify-content: center;
		}

		&-item {
			padding: 3px 10px;
			color: $white;
			font-size: 12px;
			text-transform: uppercase;
			border: 1px solid $white;
			border-radius: 4px;
			opacity: 0.75;
			margin-right: 10px;

			@include mobile {
				margin-bottom: 10px;
			}
		}
	}
}
.share-dropdown-wrapper {
	position: relative;
}

.share-dropdown {
	background-color: #fff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 -1px 0 rgba(0, 0, 0, 0.02);
	@include rounded($border-radius);
	padding: 10px 0;
	position: absolute;
	width: 150px;
	top: 100%;
	margin-top: 5px;
	left: 50%;
	transform: translateX(-50%);
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	z-index: 1;

	&::before,
	&::after {
		content: "";
		position: absolute;
		bottom: 100%;
		left: 50%;
		height: 0;
		width: 0;
		pointer-events: none;
	}

	&::before {
		margin-left: -9px;
		border: 9px solid transparent;
		border-bottom-color: rgba(0, 0, 0, 0.21);
	}

	&::after {
		margin-left: -8px;
		border: 8px solid transparent;
		border-bottom-color: #fff;
	}

	&-icon {
		margin-right: 0.5rem !important;
		@include rtl {
			margin-left: 0.5rem !important;
		}
	}

	a {
		display: block;
		color: $font-color-base;
		padding: 7px 20px;
		text-align: left;
		@include rtl {
			text-align: right;
		}
		&:hover {
			background-color: lighten(#ddd, 7%);
			color: $font-color-base;
		}
	}
}

.share-dropdown-wrapper:hover .share-dropdown {
	visibility: visible;
	opacity: 1;
	margin-top: 15px;
}

.top-investors-wrapper {
	@include mobile {
		margin-top: 30px;
	}
	.media {
		border: none;
		@include rounded($border-radius);
		padding: 20px;
		margin-bottom: 20px;
		transition: all 300ms ease-in-out;
		border-radius: 0.8rem;
		box-shadow: 0 0 17px rgba(111, 111, 111, 0.08);
		height: 100%;
		align-items: stretch;

		&:hover {
			@include box-shadow(0, 4px, 5px, rgba(67, 54, 102, 0.1));
		}

		&:last-child {
			margin-bottom: 0;
		}

		.rounded-circle {
			border: 8px solid #ebe8ff;
			object-fit: cover;
		}

		.media-body {
			h5 {
				font-size: 20px;
				font-weight: $font-bold;
				margin-bottom: 5px;
			}

			p {
				color: $font-color-light;
				margin-bottom: 0;
				margin-top: 5px;

				strong {
					color: $font-color-base;
				}

				a {
					word-break: break-word;
					color: $font-color-base;
					text-decoration: underline;
					font-weight: $font-bold;

					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
}
.react-select__option--is-focused:not(.react-select__option--is-selected) {
	background-color: rgba(90, 97, 105, 0.10196078431372549);
}

.characters-limit {
	position: absolute;
	bottom: -9px;
	right: 15px;
	font-size: 10px;
	border: 1px solid #ced4da;
	padding: 4px 10px;
	line-height: 1;
	background-color: #fff;
	border-radius: 20px;
	display: none;
}

.form-control {
	&:focus ~ .characters-limit {
		display: block;
	}
	&.no-invalid-icon {
		//background: transparent;
		background-position: right 30px center;
		@include rtl {
			background-position: left 30px center !important;
		}
	}
}

.project-card {
	border: none;
	box-shadow: 0 0 21px #6f6f6f3d;
}

.ribbon-warning {
	span {
		background-color: #ffc107;
		color: #000000;
		border-bottom: 1px solid #ffbdbd;
	}
}

.ribbon-success {
	span {
		background-color: #28a745;
		color: #ffffff;
		border-bottom: 1px solid #ffbdbd;
	}
}

.ribbon-primary {
	span {
		background-color: #ff0000;
		color: #ffffff;
		border-bottom: 1px solid #ffbdbd;
	}
}

.ribbon {
	width: 100px;
	height: 100px;
	overflow: hidden;
	position: absolute;
	top: -10px;
	right: -10px;
	span {
		width: 158px;
		position: absolute;
		text-align: center;
		font-size: 15px;
		font-weight: 600;
		left: -18px;
		top: 27px;
		transform: rotate(45deg);
	}
}

.ribbon::before,
.ribbon::after {
	position: absolute;
	z-index: -1;
	content: "";
	display: block;
	background-color: #ba0000;
}

.ribbon-success:before,
.ribbon-success:after {
	background: #2a762e;
}

.ribbon-warning:before,
.ribbon-warning:after {
	background: #d3aa19;
}

.ribbon {
	&::before {
		top: 0px;
		left: 5px;
		width: 17px;
		height: 21px;
	}
	&:after {
		bottom: 5px;
		right: 0;
		height: 17px;
		width: 10px;
	}
}

.custom-control {
	z-index: 0;
}

.phone-mobile-center {
	@include mobile {
		text-align: center;
	}
}

.raise-details-item {
	.fc-light {
		@include mobile {
			max-width: 300px;
		}
		@include md-mobile {
			max-width: 200px;
		}
		@include sm-mobile {
			max-width: 100%;
			text-align: center;
		}
	}
}

.btn {
	@include rounded($btn-border-radius);
}

.campaign-banner-wrap {
	.campaign-media-img {
		background-color: rgba(255, 255, 255, 0.4);
		@include rounded($box-medium-radius);
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.22);
	}
}

.background-box {
	background-color: #f7f7f7;
	border-radius: 20px;
	padding: 50px 10px;
}

.section-p-60 {
	padding-top: 60px;
	padding-bottom: 60px;
}

.documents-title {
	@include medium-small-width {
		line-height: 1.5rem;
	}
}

.ck.ck-dropdown .ck-dropdown__arrow {
	z-index: 0 !important;
}

.section-light-bg {
	background-color: #f7f2ef;
}
.section-dark-bg {
	background-color: #dfc9b1;
}
.section-darkest-bg {
	background-color: #5c5248;
}
.home-custom-container {
	max-width: 86%;
	@include desktop-small {
		max-width: 94%;
	}
	@include desktop {
		max-width: 96%;
	}
	@include mobile {
		max-width: 98%;
	}
}
.view-port-100vh {
	height: 100vh;
}

.project-card-location {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	p {
		display: inline;
		&:after {
			content: " \A\A";
			white-space: pre;
		}
	}
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
	color: var(--background-secondary-color);
}

.password-visibility-toggle {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	background-color: transparent;
	border: 0;
	z-index: 3;
	@include rtl {
		right: auto;
		left: 0;
	}
}
