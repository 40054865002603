html {
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	font-family: $font-family-base;
	font-size: $font-size-base;
	font-weight: $font-regular;
	color: $font-color-base;
	scroll-behavior: smooth;
	scroll-snap-type: y proximity;
	@include desktop-small {
		scroll-snap-type: none;
	}
}
// .home-scroll-snap{
// 	scroll-snap-align: start;
// 	height: 100vh;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	flex-direction: column;
// 	@include desktop-small{
// 		height: auto;
// 	}
// }

*,
*:before,
*:after {
	box-sizing: inherit;
}

.clearfix:after {
	clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	@include rtl {
		display: block;
		text-align: right;
	}
}

a {
	color: var(--background-color-front);
	transition: all 0.15s ease-in-out;

	&:hover {
		text-decoration: none;
		color: var(--background-secondary-color);
	}
}

img {
	max-width: 100%;
}

th,
td {
	@include rtl {
		text-align: right;
	}
}
.section {
	padding-top: $section-padding;
	padding-bottom: $section-padding;

	@include tablet {
		padding-top: $section-tablet-padding;
		padding-bottom: $section-tablet-padding;
	}

	@include mobile {
		padding-top: $section-mobile-padding;
		padding-bottom: $section-mobile-padding;
	}
}

.btn {
	padding: 16px 40px;
	font-weight: $font-bold;
	line-height: 1;
	@include rounded($border-radius);

	&-sm {
		padding: 12px 20px;
	}

	&-xs {
		padding: 7px 10px;
		font-size: 13px;
	}

	&:focus {
		box-shadow: none !important;
	}

	&.btn-primary,
	&.btn-primary:not(:disabled):not(.disabled).active,
	&.btn-primary:not(:disabled):not(.disabled):active {
		background-color: var(--background-color-front);
		border-color: var(--background-color-front);
		color: white;

		&:hover,
		&:active {
			background-color: var(--background-secondary-color);
			border-color: var(--background-secondary-color);
			color: white;
		}
	}

	&.btn-outline-primary {
		color: var(--background-color-front);
		border-color: var(--background-color-front);

		&:hover {
			color: $white;
			background-color: var(--background-color-front);
		}
	}
}

.btn-fw-200 {
	width: 200px;
}
.card-title {
	@include rtl {
		text-align: right;
	}
}

.react-select-container {
	.react-select {
		&__control {
			min-height: $input-height;
			border: $input-border solid $input-border-color;
			box-shadow: none;

			&:hover {
				border-color: $input-border-color;
			}
		}

		&__indicator-separator {
			display: none;
		}

		&__menu {
			margin-top: 2px;
		}

		&__option {
			background-color: $white;

			&:hover {
				background-color: lighten(#ddd, 10%);
			}

			&--is-selected {
				background-color: lighten(#ddd, 7%);
				color: $font-color-base;

				&:hover {
					background-color: lighten(#ddd, 7%);
					color: $font-color-base;
				}
			}
		}
	}
}

.form-control {
	height: $input-height;
	@include rounded($border-radius);
	border: $input-border solid $input-border-color;
	color: $font-color-base;

	&:focus {
		border-color: $input-border-color;
		@include box-shadow(0, 2px, 4px, rgba(67, 54, 102, 0.1));
	}
	&.is-invalid {
		@include rtl {
			padding-left: 40px;
		}
	}
}
.form-group {
	@include rtl {
		text-align: right;
	}
}
.custom-file {
	height: $input-height;

	&-input {
		height: $input-height;
	}

	&-label {
		margin-bottom: 0;
		display: flex;
		align-items: center;
		height: $input-height;
		@include rounded($border-radius);
		border: $input-border solid $input-border-color;

		&::after {
			height: calc(var(--input-height) - var(--input-border));
			font-weight: $font-bold;
			font-size: 15px;
			display: flex;
			align-items: center;
			padding-left: 20px;
			padding-right: 20px;

			@include rtl {
				left: 0;
				right: auto;
				border-radius: 0.25rem 0 0 0.25rem;
			}
		}
	}
}
.custom-file-input:focus ~ .custom-file-label {
	border-color: $input-border-color;
	@include box-shadow(0, 2px, 4px, rgba(67, 54, 102, 0.1));
}

.custom-checkbox {
	@include rtl {
		padding-left: 0;
		padding-right: 20px;
	}
}
.custom-control-label::before {
	border-color: $input-border-color;
}

.custom-control-label::before,
.custom-control-label::after {
	@include rtl {
		left: unset;
		right: -1.5rem;
	}
}

.custom-control-input:checked ~ .custom-control-label::before {
	border-color: var(--background-color-front);
	background-color: var(--background-color-front);
}

.custom-control-input {
	@include rtl {
		left: auto;
		right: 0;
	}
	&:disabled {
		opacity: 0 !important;
	}
}

// verification redio btn

.verification [type="radio"]:checked,
.verification [type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
}
.verification [type="radio"]:checked + label,
.verification [type="radio"]:not(:checked) + label {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
	color: #666;
	@include rtl {
		padding-right: 28px;
	}
}

.verification [type="radio"]:checked + label:before,
.verification [type="radio"]:not(:checked) + label:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	border: 1px solid #ddd;
	border-radius: 100%;
	background: #fff;
	@include rtl {
		right: -0.25rem;
	}
}
.verification [type="radio"]:checked + label:after,
.verification [type="radio"]:not(:checked) + label:after {
	content: "";
	width: 18px;
	height: 18px;
	background: #b88b59;
	position: absolute;
	top: 0px;
	left: 0px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	@include rtl {
		right: -0.25rem;
	}
}
.verification [type="radio"]:not(:checked) + label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}
.verification [type="radio"]:checked + label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}
// verification redio btn

.form-label {
	font-weight: $font-medium;
	@include rtl {
		display: block;
		text-align: right;
	}
}

.label {
	@include rtl {
		display: block;
		text-align: right;
	}
}

.invalid-feedback {
	@include rtl {
		text-align: right;
	}
}
.text-right {
	text-align: right !important;
	@include rtl {
		text-align: right;
	}
}
.progress {
	background-color: #eaeef3;

	.progress-bar {
		background-color: var(--background-color-front);
	}
}

.fc-light {
	color: $font-color-light;
}

.fc-base {
	color: $font-color-base;
}

.avatar-xl {
	width: 160px;
	height: 160px;
	object-fit: cover;
}

.avatar-lg {
	width: 120px;
	height: 120px;
	object-fit: cover;
}

.avatar-md {
	width: 80px;
	height: 80px;
	object-fit: cover;
}

.avatar-sm {
	width: 50px;
	height: 50px;
	object-fit: cover;
}

.avatar-xsm {
	width: 30px;
	height: 30px;
	object-fit: cover;
}

.avatar-25 {
	width: 25px;
	height: 25px;
	object-fit: cover;
}

.avatar-rounded {
	border-radius: 50%;
}

.avatar-radius-5 {
	border-radius: 5px;
}

.text-primary {
	color: var(--background-color-front) !important;
}
.text-important {
	color: $text-important;
}
.react-select-container {
	&.is-invalid {
		.react-select__control {
			border-color: #dc3545;
		}
	}
}

.is-invalid {
	@include rtl {
		background-position: left calc(0.375em + 0.1875rem) center !important;
	}
}

.custom {
	&-closing {
		&-icon {
			color: $red;
		}
	}
}

.project {
	&-card {
		&-ending-soon {
			font-size: 15px;
			font-weight: 500;
		}
	}
}
.custom-file-label {
	overflow: hidden;
	white-space: nowrap;
	// For nice text overflow we need also padding-right, problem is
	// the button width is not constant, but 8em should work for most cases
	padding-right: 8em;
	text-overflow: ellipsis;

	@include rtl {
		padding-left: 8em;
		padding-right: 0.75rem;
	}
	[lang="ar"] &::after {
		content: "تصفح" !important;
	}
	[lang="fr"] &::after {
		content: "Parcourir" !important;
	}
}
