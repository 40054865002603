:root {
	--background-color-front: #6048fe;
	--background-secondary-color: #000;
}

@import "./variables";
@import "./mixins";
@import "./fonts";

@import "./core";

@import "./custom";
@import "./spinner";
