@import "./variables";

@font-face {
	font-family: "montserrat-arabic";
	font-weight: 300;
	font-display: swap;
	src: local("montserrat-arabic"),
		url("../montserrat-arabic/Montserrat-Arabic-Light.woff") format("woff");
}
@font-face {
	font-family: "montserrat-arabic";
	font-weight: 400;
	src: local("montserrat-arabic"),
		url("../montserrat-arabic/Montserrat-Arabic-Regular.woff") format("woff");
}
@font-face {
	font-family: "montserrat-arabic";
	font-weight: 500;
	src: url("../montserrat-arabic/Montserrat-Arabic-Medium.woff") format("woff");
}
@font-face {
	font-family: "montserrat-arabic";
	font-weight: 600;
	src: local("montserrat-arabic"),
		url("../montserrat-arabic/Montserrat-Arabic-SemiBold.woff") format("woff");
}
@font-face {
	font-family: "montserrat-arabic";
	font-weight: 700;
	src: local("montserrat-arabic"),
		url("../montserrat-arabic/Montserrat-Arabic-Bold.woff") format("woff");
}
@font-face {
	font-family: "montserrat-arabic";
	font-weight: 800;
	src: local("montserrat-arabic"),
		url("../montserrat-arabic/Montserrat-Arabic-ExtraBold.woff") format("woff");
}
