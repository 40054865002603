@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;

.ec-navbar {
	background-color: #5c5248;
	@include box-shadow(0, 0, 30px, rgba(67, 54, 102, 0.1));
	padding-top: 20px;
	padding-bottom: 20px;
	.navbar-custom-padding {
		padding: 0rem 2rem;
		@include desktop-mediam-width {
			padding: 0;
		}
	}
	.navbar-nav {
		align-items: center;
		@include rtl {
			margin-left: 0;
			margin-right: auto;
		}

		@include tablet {
			display: block;
			margin-top: 10px;
		}

		.nav-link {
			margin: 0px 24px;
			color: $white;
			font-size: 16px;
			font-weight: 300;
			text-align: center;
			&:first-child {
				margin-left: 0px;
			}
			&:last-child {
				margin-right: 0px;
			}
			@include desktop-mediam-width {
				margin: 0px 17px;
			}
			@include desktop-max-1199 {
				margin: 0px 3px;
				font-size: 14px;
			}
			@include desktop-max-1156 {
				margin: 0px 6px;
			}

			// @include rtl {
			// 	margin-right: 50px;
			// 	margin-left: 0;
			// 	@include desktop {
			// 		margin-right: 7px;
			// 	}
			// }

			&.nav-user-dropdown-wrapper,
			&.top-notification {
				@include rtl {
					margin-right: 1rem !important;
					margin-left: 0 !important;
				}
			}

			&:hover {
				color: var(--background-color-front);
			}

			&.btn {
				padding: 13px 30px;
				color: #5c5248;
				background-color: #dfc9b1;
				border-color: #dfc9b1;
				font-weight: $font-regular;
				@include tablet {
					display: inline-block;
				}
			}

			@include tablet {
				margin-left: 0;
				padding-top: 15px;
				padding-bottom: 15px;
				border-top: 1px solid #ddd;

				&:first-child {
					border: 0;
				}
			}
		}
	}
	.sign-in-btn {
		padding: 20px 60px;
		background-color: #e3c8b3;
		border-color: #e3c8b3;
		font-weight: 300;
		color: #5c5248;
		&:hover {
			background-color: var(--background-color-front);
			border-color: var(--background-color-front);
		}
		@include desktop {
			padding-left: 50px;
			padding-right: 50px;
		}
		@include desktop-max-1156 {
			padding-left: 40px;
			padding-right: 40px;
		}
		@include desktop-max-1035 {
			padding-left: 20px;
			padding-right: 20px;
		}
		@include tablet {
			width: fit-content;
			margin-bottom: 5px;
		}
	}
}

.nav-user {
	&-dropdown-wrapper {
		padding-top: 0;
		padding-bottom: 0;
		position: relative;
	}
	&-info {
		@include rtl {
			padding-bottom: 30px;
		}
	}
	&-img {
		width: 45px;
		height: 45px;
		object-fit: cover;
		border-radius: $btn-border-radius;
		@include rtl {
			float: right;
		}
	}

	&-name {
		display: none;
		color: #fff;
		@include rtl {
			float: right;
			margin-right: 10px;
			padding-top: 10px;
		}
	}

	&-dropdown {
		position: absolute;
		width: 220px;
		z-index: 10;
		top: 100%;
		padding-top: 10px;
		right: 0;
		visibility: hidden;
		opacity: 0;
		-webkit-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;

		@include rtl {
			left: 0;
			right: auto;
		}

		&-inner {
			background-color: #fff;
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 -1px 0 rgba(0, 0, 0, 0.02);
			@include rounded($border-radius);
			padding: 10px 0;
			position: relative;

			&::before,
			&::after {
				content: "";
				position: absolute;
				bottom: 100%;
				right: 20px;
				height: 0;
				width: 0;
				pointer-events: none;

				@include rtl {
					left: 30px;
					right: auto;
					display: none;
				}
			}

			&::before {
				margin-left: -9px;
				border: 9px solid transparent;
				border-bottom-color: rgba(0, 0, 0, 0.05);
			}

			&::after {
				margin-left: -8px;
				border: 8px solid transparent;
				border-bottom-color: #fff;
			}
		}

		&-item {
			display: block;
			color: $font-color-base;
			padding: 10px 20px;
			background: #ffffff;
			color: #444;
			@include mobile {
				background: #5c5248;
				color: #fff;
				border-bottom: 1px solid #777777;
			}

			&:hover {
				background-color: lighten(#ddd, 7%);
				color: $font-color-base;
			}
		}
	}
}

.nav-user-dropdown-wrapper:hover .nav-user-dropdown {
	visibility: visible;
	opacity: 1;
	padding-top: 20px;
}

.unread-number {
	// background-color: $red;
	color: var(--background-color-front) !important;
	padding-top: 11px;
	padding-bottom: 3px;
	@include rounded($border-radius);
}

.nav-user-arrow {
	display: none;
	font-size: 1rem;
	@include rtl {
		position: absolute;
		font-size: 1.5rem;
		right: 95%;
		padding-top: 7px;
	}
}

.dropdown-item-messages,
.dropdown-item-notifications {
	display: none;
}

@include tablet {
	.top-messages,
	.top-notification {
		display: none;
	}

	.nav-user-dropdown-wrapper {
		margin-left: 0 !important;
	}

	.nav-user-name {
		display: initial;
		margin-left: 15px;
		font-weight: $font-bold;
	}

	.nav-user-info {
		position: relative;
	}

	.nav-user-arrow {
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		opacity: 0.5;
	}

	.nav-user-dropdown {
		position: relative;
		width: 100%;
		z-index: 10;
		height: 0;
	}

	.nav-user-dropdown-wrapper:hover .nav-user-dropdown {
		height: auto;
	}

	.nav-user-dropdown-inner {
		box-shadow: none;
		border-radius: 0;
		padding: 0;
		position: relative;
	}

	.nav-user-dropdown-item {
		padding-left: 15px;
		padding-right: 0;
	}

	.dropdown-item-messages,
	.dropdown-item-notifications {
		display: block;
	}
}

.primary-site-logo {
	max-height: 50px;
	max-width: 220px;
	@include desktop {
		max-width: 150px;
	}
}

.fos25 {
	font-size: 25px;
}
