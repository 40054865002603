@use "../../assets/sass/variables";
@use "../../assets/sass/mixins" as *;

.maintenance {
	padding: 20px 0 0 0;
	color: var(--background-color-front);

	@include tablet {
		padding: 30px 0 0 0;
	}

	&-row {
		@include tablet {
			flex-direction: column;
			justify-content: center;
		}

		@include md-mobile {
			flex-direction: row;
			justify-content: center;
		}
	}

	&-text-wrap {
		padding-left: 200px;
		justify-content: center;

		@include desktop-small {
			padding-left: 80px;
		}

		@include tablet {
			padding-left: 20px;
			text-align: center;
		}

		@include md-mobile {
			padding-left: 0;
			text-align: center;
		}

		.maintenance-heading {
			font-size: 50px;
		}
	}
	&-img-wrap {
		margin-top: 20px;
		margin-bottom: 20px;
		margin-left: 70px;

		@include tablet {
			margin-top: 70px;
			margin-left: 0;
		}
	}
	&-img {
		width: 45%;
		border-radius: 25px;

		@include tablet {
			width: 80%;
		}

		@include md-mobile {
			width: 80%;
		}
	}
}
