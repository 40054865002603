$desktop-full-width: 1980px;
$desktop-mediam-width: 1441px;
$desktop-small-width: 1366px;
$desktop-1200-width: 1200px;
$desktop-width: 1024px;
$tablet-width: 992px;
$ipad-air: 821px;
$mobile-width: 768px;
$md-mobile-width: 576px;
$mds-mobile-width: 426px;
$iphone-6-7-8-plus: 415px;
$pixel-5: 394px;
$iphone-12pro: 391px;
$x-mobile-width: 376px;
$sm-one-mobile-width: 361px;
$sm-mobile-width: 330px;
$smallest-mobile-width: 321px;
$desktop-medium-size: 1280px;
$galaxy-fold-size: 281px;
$ipohe-13-size: 429px;
$phone-width-667: 668px;
$phone-landscape-844: 845px;
$phone-landscape-851: 852px;
$phone-landscape-740: 741px;
$phone-landscape-915: 916px;
$phone-landscape-896: 897px;
$desktop-max-1156: 1156px;
$desktop-max-1035: 1035px;
$desktop-max-1199: 1199px;
$desktop-max-1130: 1130px;

@mixin desktop {
	@media (max-width: #{$desktop-width}) {
		@content;
	}
}

@mixin desktop-max-1199 {
	@media (max-width: #{$desktop-max-1199}) {
		@content;
	}
}

@mixin desktop-max-1130 {
	@media (max-width: #{$desktop-max-1130}) {
		@content;
	}
}

@mixin desktop-max-1156 {
	@media (max-width: #{$desktop-max-1156}) {
		@content;
	}
}

@mixin desktop-max-1035 {
	@media (max-width: #{$desktop-max-1035}) {
		@content;
	}
}

@mixin desktop-small {
	@media (max-width: #{$desktop-small-width}) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: #{$tablet-width - 1px}) {
		@content;
	}
}

@mixin ipad-air {
	@media (max-width: #{$ipad-air - 1px}) {
		@content;
	}
}

@mixin desktop-mobile {
	@media (min-width: $mobile-width) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: #{$mobile-width - 1px}) {
		@content;
	}
}

@mixin md-mobile {
	@media (max-width: #{$md-mobile-width - 1px}) {
		@content;
	}
}

@mixin xxs-mobile {
	@media (max-width: #{$smallest-mobile-width}) {
		@content;
	}
}

@mixin sm-mobile {
	@media (max-width: #{$sm-mobile-width - 1px}) {
		@content;
	}
}

@mixin sm-one-mobile {
	@media (max-width: #{$sm-one-mobile-width - 1px}) {
		@content;
	}
}

@mixin desktop-mediam-width {
	@media (max-width: #{$desktop-mediam-width}) {
		@content;
	}
}

@mixin desktop-medium-size {
	@media (max-width: #{$desktop-medium-size}) {
		@content;
	}
}

@mixin medium-small-width {
	@media (max-width: #{$mds-mobile-width - 1px}) {
		@content;
	}
}

@mixin x-mobile {
	@media (max-width: #{$x-mobile-width - 1px}) {
		@content;
	}
}

@mixin iphone-6to8 {
	@media (max-width: #{$iphone-6-7-8-plus - 1px}) {
		@content;
	}
}

@mixin iphone-12pro {
	@media (max-width: #{$iphone-12pro - 1px}) {
		@content;
	}
}

@mixin pixel-5 {
	@media (max-width: #{$pixel-5 - 1px}) {
		@content;
	}
}

@mixin galaxy-fold-mobile {
	@media (max-width: #{$galaxy-fold-size - 1px}) {
		@content;
	}
}
@mixin iphone-13 {
	@media (max-width: #{$ipohe-13-size - 1px}) {
		@content;
	}
}

@mixin mobile-width-667 {
	@media (max-width: #{$phone-width-667 - 1px}) {
		@content;
	}
}

@mixin phone-landscape-844 {
	@media (max-width: #{$phone-landscape-844 - 1px}) {
		@content;
	}
}

@mixin phone-landscape-851 {
	@media (max-width: #{$phone-landscape-851 - 1px}) {
		@content;
	}
}

@mixin phone-landscape-740 {
	@media (max-width: #{$phone-landscape-740 - 1px}) {
		@content;
	}
}

@mixin phone-landscape-915 {
	@media (max-width: #{$phone-landscape-915 - 1px}) {
		@content;
	}
}

@mixin phone-landscape-896 {
	@media (max-width: #{$phone-landscape-896 - 1px}) {
		@content;
	}
}

@mixin desktop-mediam-min-width {
	@media (min-width: #{$desktop-mediam-width}) {
		@content;
	}
}

@mixin desktop-full-max-width {
	@media (max-width: #{$desktop-full-width}) {
		@content;
	}
}

@mixin desktop-1200-min-width {
	@media (min-width: #{$desktop-1200-width}) {
		@content;
	}
}

@mixin desktop-1200-max-width {
	@media (max-width: #{$desktop-1200-width - 1px}) {
		@content;
	}
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
	@if $inset {
		-webkit-box-shadow: inset $top $left $blur $color;
		-moz-box-shadow: inset $top $left $blur $color;
		box-shadow: inset $top $left $blur $color;
	} @else {
		-webkit-box-shadow: $top $left $blur $color;
		-moz-box-shadow: $top $left $blur $color;
		box-shadow: $top $left $blur $color;
	}
}

@mixin button($color: $red, $text_color: $white) {
	display: inline-block;
	outline: none;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	font: 14px/100% Arial, Helvetica, sans-serif;
	padding: 0.5em 2em 0.55em;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
	@include rounded();

	color: $text_color !important;
	font-weight: bold;
	border: solid 1px darken($color, 18%);
	background: $color;

	&:hover {
		text-decoration: none;
		background: saturate($color, 10%);
	}

	&:active {
		color: saturate($color, 15%);
	}
}

@mixin text-field {
	display: inline-block;
	outline: none;
	text-decoration: none;
	padding: 0.5em;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
	@include rounded();
}

@mixin rounded($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

//Padding mixin
@mixin padding($top, $right, $bottom, $left) {
	padding-top: $top;
	padding-right: $right;
	padding-bottom: $bottom;
	padding-left: $left;
}
//Margin mixin
@mixin margin($top, $right, $bottom, $left) {
	margin-top: $top;
	margin-right: $right;
	margin-bottom: $bottom;
	margin-left: $left;
}

@mixin rtl {
	[dir="rtl"] & {
		@if & {
			& {
				@content;
			}
		} @else {
			@content;
		}
	}
}

// @mixin rtl($type, $amount) {
// 	@if $type == padding {
// 		@if $isRTL {
// 			padding-right: $amount;
// 		} @else {
// 			padding-left: $amount;
// 		}
// 	} @else if $type == margin {
// 		@if $isRTL {
// 			margin-right: $amount;
// 		} @else {
// 			margin-left: $amount;
// 		}
// 	} @else if $type == text-align {
// 		@if $isRTL {
// 			@if $amount == left {
// 				text-align: right;
// 			} @else if $amount == right {
// 				text-align: left;
// 			}
// 		} @else {
// 			@if $amount == left {
// 				text-align: left;
// 			} @else if $amount == right {
// 				text-align: right;
// 			}
// 		}
// 	}
// }
